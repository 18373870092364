import { useEffect, useState } from 'react'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import auth from '@itau-loans-www/shopping/src/services/http/auth'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from './services'

const useBankData = (formProps) => {
  const [banks, setBanks] = useState([])
  const [disabledFields, setDisabledFields] = useState({
    bank: false,
    bank_code: false,
    branch: false,
    account_number: false,
    account_digit: false
  })
  const { product } = useProposal()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)

  const { changeLeadData } = useLead()

  const productCode = product?.proposal?.productCode
  const isFGTS = productCode === 'FGTS'

  const onSubmit = async (value) => {
    analytics.track('buttonClick', {
      currentPage: 'proposta/dados-bancarios',
      detail: 'click-step-dados-bancarios',
      customLayer: {
        description: productCode
      }
    })
    try {
      changeLeadData({
        banking_data: {
          ...value
        }
      })

      setSubmitLoading(true)

      const { final_screen } = await services.putLeadBankingData(value)

      goTo(final_screen)
    } catch (error) {
      console.log({ error })
    } finally {
      setSubmitLoading(false)
    }
  }

  const options = banks.map((bank) => {
    return {
      label: `${bank['bank_code']} - ${bank['bank_name']}`,
      value: bank['bank_code']
    }
  })

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'proposta/dados-bancarios',
      customLayer: {
        description: productCode
      }
    })
    auth({})
      .then(async () => {
        setGetLoading(true)
        const bankData = await services.getBanks(productCode)
        setBanks(bankData)

        return services
          .getLeadBankingData()
          .then(({ previous_step, banking_data }) => {
            changeLeadData({
              previousStep: previous_step
            })
            if (banking_data?.inserted_automatically) {
              const bank = bankData.find(
                (item) => item.bank_code === banking_data?.bank_code
              )

              setDisabledFields({
                bank: bank?.bank_code && bank?.bank_name,
                bank_code: banking_data?.bank_code,
                branch: banking_data?.branch,
                account_number: banking_data?.account_number,
                account_digit: banking_data?.account_digit
              })

              if (bank?.bank_code && bank?.bank_name) {
                formProps.setValue(
                  'bank',
                  `${bank?.bank_code} - ${bank?.bank_name}`
                )
                formProps.setValue('bank_code', banking_data?.bank_code)
              }

              formProps.setValue('branch', banking_data?.branch)
              formProps.setValue('account_number', banking_data?.account_number)
              formProps.setValue('account_digit', banking_data?.account_digit)
            }
          })
      })
      .finally(() => setGetLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isFGTS,
    options,
    onSubmit,
    disabledFields,
    submitLoading,
    getLoading
  }
}

export default useBankData
